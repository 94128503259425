import React from "react";

const Profile = () => {
  return (
    <>
      <h2
        style={{
          alignSelf: "center",
          textAlign: "center",
        }}
      >
        Working on Progress
      </h2>
    </>
  );
};

export default Profile;
