import React from "react";

const Dashboard = () => {
  return (
    <>
      <h1
        style={{
          alignSelf: "center",
          textAlign: "center",
        }}
      >
        Welcome To WebDuality Technologies Pvt. Ltd.
      </h1>
    </>
  );
};

export default Dashboard;
